import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["textarea", "overlay", "nextButton", "invalidButton", "mergeTag"]

  static values = {
    validTags: Array
  }

  connect() {
    this.validTags = [
      '{{greeting}}',
      '{{sender_first_name}}',
      '{{custom_1}}',
      '{{custom_2}}',
      '{{custom_3}}',
      '{{custom_4}}'
    ]

    this.updateOverlay()
    this.updateButtons()
  }

  insertMergeTag(event) {
    event.preventDefault()
    const tag = event.currentTarget.dataset.tag + " "

    this.textareaTarget.focus()
    const start = this.textareaTarget.selectionStart
    const end = this.textareaTarget.selectionEnd

    const before = this.textareaTarget.value.slice(0, start)
    const after = this.textareaTarget.value.slice(end)
    this.textareaTarget.value = before + tag + after
    this.textareaTarget.selectionStart = this.textareaTarget.selectionEnd = start + tag.length

    this.textareaTarget.dispatchEvent(new InputEvent('input', { bubbles: true }))

    this.updateOverlay()
    this.updateButtons()
  }

  updateContent() {
    this.updateOverlay()
    this.updateButtons()
  }

  updateOverlay() {
    const text = this.textareaTarget.value
    this.overlayTarget.innerHTML = this.highlightTags(text)
  }

  updateButtons() {
    const invalidTags = this.overlayTarget.querySelectorAll('.invalid-tag')
    if (invalidTags.length > 0) {
      this.nextButtonTarget.classList.add('d-none')
      this.invalidButtonTarget.classList.remove('d-none')
    } else {
      this.nextButtonTarget.classList.remove('d-none')
      this.invalidButtonTarget.classList.add('d-none')
    }
  }

  highlightTags(text) {s
    text = this.escapeHtml(text)
    return text.replace(/{{[^{}]+}}/g, (match) => {
      const isValid = this.validTags.includes(match)
      const className = isValid ? 'valid-tag' : 'invalid-tag'
      return `<span class="${className}">${match}</span>`
    })
  }

  escapeHtml(text) {
    const div = document.createElement('div')
    div.textContent = text
    return div.innerHTML
  }
}